import { createSelector } from '@reduxjs/toolkit';

import { creditConsentSlice, ICreditConsentState } from 'store/redux/modules/creditConsent/reducers';
import { RootState } from 'types/store';

import { CreditCheckStatusEnum } from './types';

export const selectCreditConsent = (state: RootState): ICreditConsentState => state.creditConsent;
export const selectCreditConsentStatusSoft = (state: RootState): CreditCheckStatusEnum | null => {
    const { statusSoft } = state.creditConsent;
    return statusSoft;
};

export const selectCreditConsentStatus = (state: RootState): CreditCheckStatusEnum | null => {
    const { status } = state.creditConsent;
    return status;
};

export const selectCreditConsentStatusSoftMemoized = createSelector(
    [selectCreditConsentStatusSoft],
    (statusSoft) => statusSoft
);

export const selectCreditConsentStatusMemoized = createSelector([selectCreditConsentStatus], (status) => status);

export { performRPOSCreditCheckAsync, postCreditConsentAsync } from './actions';

export const { setSubmitted } = creditConsentSlice.actions;

export default creditConsentSlice.reducer;
